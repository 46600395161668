module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Profesjonalny druk 3D dla przemysłu | 3D Phoenix","short_name":"Markforged | 3D Phoenix","description":"Odkryj Markforged – niezawodny system druku 3D dla przemysłu. Precyzyjne urządzenia, intuicyjne oprogramowanie i materiały klasy przemysłowej gwarantują trwałe wydruki. Usprawnij produkcję dzięki zaawansowanej technologii druku 3D.","start_url":"/","lang":"pl","background_color":"#000000","theme_color":"#000000","display":"standalone","icon":"src/images/maskable_icon.png","cache_busting_mode":"none","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
